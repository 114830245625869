import OrderFormTemplate from './OrderFormTemplate';
import OrderForm45Content from './OrderForm45Content';

export default function OrderForm4({
  onNext,
  onBack,
  status,
  formik,
  getGeopoint,
}) {
  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      zipFrom: true,
      countryFrom: true,
      addressFrom: true,
      unitFrom: true,
      cityFrom: true,
      stateFrom: true,
      bedroomsFrom: true,
      parkingFrom: true,
    });

    const errors = await formik.validateForm();
    if (
      !errors.zipFrom &&
      !errors.countryFrom &&
      !errors.addressFrom &&
      !errors.unitFrom &&
      !errors.cityFrom &&
      !errors.stateFrom &&
      !errors.bedroomsFrom &&
      !errors.parkingFrom
    )
      onNext();
  };

  return (
    <OrderFormTemplate
      header='Pick Up Address'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
    >
      <OrderForm45Content
        formik={formik}
        getGeopoint={getGeopoint}
        zip='zipFrom'
        country='countryFrom'
        address='addressFrom'
        unit='unitFrom'
        state='stateFrom'
        city='cityFrom'
        bedrooms='bedroomsFrom'
        parking='parkingFrom'
      />
    </OrderFormTemplate>
  );
}
