import ClipLoader from 'react-spinners/ClipLoader';
import './Spinner.scss';

export default function Spinner({ show }) {
  return (
    <div className={'spinner' + (show ? ' show' : '')}>
      <ClipLoader size={80} />
    </div>
  );
}
