import OrderFormTemplate from './OrderFormTemplate';
import LabelInput from './LabelInput';
import './OrderForm2.scss';

export default function OrderForm2({ onNext, onBack, status, formik }) {
  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });

    const errors = await formik.validateForm();

    if (!errors.firstName && !errors.lastName && !errors.email && !errors.phone)
      onNext();
  };

  return (
    <OrderFormTemplate
      header='How do we get in touch?'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
    >
      <div className='label-input-wrapper'>
        <LabelInput
          label='First Name*'
          placeholder='Your first name'
          name='firstName'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : null
          }
        />
        <LabelInput
          label='Last Name*'
          placeholder='Your last name'
          name='lastName'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : null
          }
        />
        <LabelInput
          label='Email Addres*'
          placeholder='Your email addres'
          type='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <div className='flag-input-wrapper'>
          <select name='country'>
            {/* <option value='USA'>&#127482;&#127480;</option>
            <option value='AUS'>&#127462;&#127482;</option> */}
            <option value='US'>USA</option>
            <option value='AU'>AUS</option>
          </select>
          <LabelInput
            label='Phone number*'
            placeholder='Your phone'
            type='tel'
            name='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : null
            }
          />
        </div>
      </div>
    </OrderFormTemplate>
  );
}
