import Autocomplete from 'react-autocomplete';
import './LabelAutocomplete.scss';

export default function LabelAutocomplete({
  name,
  label,
  error,
  value,
  items,
  onChange,
  onBlur,
  onSelect,
}) {
  return (
    <div className='label-autocomplete'>
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <Autocomplete
        items={items}
        getItemValue={(item) => item}
        renderItem={(item, isHighlighted) => (
          <div
            key={item}
            className={'item' + (isHighlighted ? ' highlighted' : '')}
          >
            <p>{item}</p>
          </div>
        )}
        value={value}
        onChange={onChange}
        inputProps={{
          name,
          onBlur,
        }}
        onSelect={onSelect}
      />
    </div>
  );
}
