import LogoText from './LogoText';
import Container from './Container';
import SignInPanel from './SignInPanel';
import IntroText from './IntroText';
import './Home.scss';

export default function Home() {
  return (
    <Container>
      <div className='centered-wrapper'>
        <div className='centered-inner'>
          <LogoText />
          <IntroText />
          <SignInPanel />
        </div>
      </div>
    </Container>
  );
}
