import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from '../components/Home';
import Order from './Order';
import { auth } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  if (error) console.error(error);

  if (!user && !loading) return <Navigate to='/' replace />;
  return <>{children}</>;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/order',
    element: (
      <ProtectedRoute>
        <Order />
      </ProtectedRoute>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
