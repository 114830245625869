import { useState, useEffect } from 'react';
import { getAdditionalUserInfo } from 'firebase/auth';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { functions } from '../utils/firebase';
import IconButton from './IconButton';
import GoogleLogo from '../assets/images/google-logo.png';
import FacebookLogo from '../assets/images/facebook-logo.png';
import AppleLogo from '../assets/images/apple-logo.png';
import SignUpLogin from './SignUpLogin';
import SignUpEmail from './SignUpEmail';
import LoginEmail from './LoginEmail';
import ForgetPwd from './ForgetPwd';
import SuccessResetPwd from './SuccessResetPwd';
import './SignInPanel.scss';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import {
  useSignInWithGoogle,
  useSignInWithFacebook,
  useSignInWithApple,
} from 'react-firebase-hooks/auth';

export default function SignInPanel() {
  const [userCred, setUserCred] = useState(null);
  const [userName, setUserName] = useState(null);
  const [showSignUpEmail, setShowSignUpEmail] = useState(false);
  const [showLoginEmail, setShowLoginEmail] = useState(false);
  const [showForgetPwd, setShowForgetPwd] = useState(false);
  const [showSuccessResetPwd, setShowSuccessResetPwd] = useState(false);
  const navigate = useNavigate();
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [signInWithFacebook] = useSignInWithFacebook(auth);
  const [signInWithApple] = useSignInWithApple(auth);
  const [userInit, executing, error] = useHttpsCallable(functions, 'user-init');

  useEffect(() => {
    const initUserInfo = async (userCred) => {
      const { displayName: name, email } = userCred.user;
      // const fcmToken = await getFcmToken();
      // const deviceInfo = getDeviceInfo();
      const fcmToken = undefined;
      const deviceInfo = undefined;

      const res = await userInit({
        name: name ?? userName,
        email,
        fcmToken,
        deviceInfo,
      });
    };

    if (userCred) {
      const { isNewUser } = getAdditionalUserInfo(userCred);
      if (isNewUser) {
        initUserInfo(userCred);
      }

      navigate('/order');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [userCred, navigate]);

  return (
    <div className='signin-panel'>
      <SignUpLogin
        onClickSignUp={() => setShowSignUpEmail(true)}
        onClickLogin={() => setShowLoginEmail(true)}
      />
      <IconButton
        text='Sign in with Google'
        icon={GoogleLogo}
        onClick={async () => {
          setUserCred(await signInWithGoogle());
        }}
      />
      <IconButton
        text='Sign in with Facebook'
        icon={FacebookLogo}
        color='white'
        background='#3b5999'
        onClick={async () => {
          setUserCred(await signInWithFacebook());
        }}
      />
      <IconButton
        text='Sign in with Apple'
        icon={AppleLogo}
        color='white'
        background='black'
        onClick={async () => {
          setUserCred(await signInWithApple());
        }}
      />
      <IconButton
        text='Sign in with Email'
        onClick={() => setShowLoginEmail(true)}
      />
      <SignUpEmail
        show={showSignUpEmail}
        onClose={() => setShowSignUpEmail(false)}
        setUserCred={setUserCred}
        setUserName={setUserName}
      />
      <LoginEmail
        show={showLoginEmail}
        onClose={() => setShowLoginEmail(false)}
        onForgetPwdClick={() => {
          setShowLoginEmail(false);
          setShowForgetPwd(true);
        }}
        setUserCred={setUserCred}
      />
      <ForgetPwd
        show={showForgetPwd}
        onClose={() => {
          setShowForgetPwd(false);
          setShowLoginEmail(true);
        }}
        onSubmit={() => {
          setShowForgetPwd(false);
          setShowSuccessResetPwd(true);
        }}
      />
      <SuccessResetPwd
        show={showSuccessResetPwd}
        onClose={() => setShowSuccessResetPwd(false)}
      />
    </div>
  );
}
