import './ProgressBar.scss';

export default function ProgressBar({ step, total }) {
  return (
    <div
      className={
        'progress-bar' + (step === 0 || step > total ? ' invisible' : '')
      }
    >
      <div
        className='done'
        style={{
          maxWidth: (step / total) * 100 + '%',
          transition: 'all 500ms',
        }}
      />
      <div className='all' />
    </div>
  );
}
