import Navigation from './Navigation';
import Container from './Container';

export default function PageTemplate({ children }) {
  return (
    <div className='page-template'>
      <Navigation />
      <Container>{children}</Container>
    </div>
  );
}
