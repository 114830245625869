import OrderFormTemplate from './OrderFormTemplate';
import LabelDate from './LabelDate';
import LabelTime from './LabelTime';
import CheckBox from './CheckBox';
import { addDays } from '../utils/miscellaneous';
import './OrderForm3.scss';

export default function OrderForm3({ onNext, onBack, status, formik }) {
  const onLabelDateChange = async (field, value) => {
    formik.setFieldValue(field, value, false);

    await formik.validateField(field);

    const newTouched = { ...formik.touched };
    newTouched[field] = value;
    formik.setTouched(newTouched);
  };

  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      storageDate: true,
    });

    const errors = await formik.validateForm();
    if (!(errors.storageDate && formik.values.storageRequired)) onNext();
  };

  return (
    <OrderFormTemplate
      header='When are you moving?'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
    >
      <div className='order-form-3-wrapper'>
        <LabelDate
          label='Pick Up Date*'
          value={formik.values.pickUpDate}
          onChange={(newVal) => onLabelDateChange('pickUpDate', newVal)}
          minDate={addDays(new Date(), 7)}
        />
        <LabelTime
          label='Pick Up Time*'
          name='pickUpTime'
          value={formik.values.pickUpTime}
          onChange={formik.handleChange}
        />
        <div className='checkbox-date-wrapper'>
          <CheckBox
            label='I require storage'
            checked={formik.values.storageRequired}
            {...formik.getFieldProps('storageRequired')}
          />
          <LabelDate
            label='Estimated Storage End Date*'
            value={formik.values.storageDate}
            onChange={(newVal) => onLabelDateChange('storageDate', newVal)}
            disabled={!formik.values.storageRequired}
            minDate={formik.values.pickUpDate}
            error={
              formik.values.storageRequired &&
              formik.touched.storageDate &&
              formik.errors.storageDate
                ? formik.errors.storageDate
                : null
            }
          />
        </div>
        <div className='checkboxes-block-wrapper'>
          <label>Packing Options:</label>
          <CheckBox
            label='I do not require packing (self pack)'
            checked={formik.values.selfPack}
            {...formik.getFieldProps('selfPack')}
          />
        </div>
      </div>
    </OrderFormTemplate>
  );
}
