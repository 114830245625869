import { useState } from 'react';
import axios from 'axios';
import LabelInput from './LabelInput';
import LabelSelect from './LabelSelect';
import LabelAutocomplete from './LabelAutocomplete';
import { apiKey, autoCompleteURL } from '../utils/googleMaps';
import './OrderForm45Content.scss';

const states = {
  US: [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ],
  AU: [
    {
      name: 'New South Wales',
      abbreviation: 'NSW',
    },
    {
      name: 'Victoria',
      abbreviation: 'VIC',
    },
    {
      name: 'Queensland',
      abbreviation: 'QLD',
    },
    {
      name: 'Tasmania',
      abbreviation: 'TAS',
    },
    {
      name: 'South Australia',
      abbreviation: 'SA',
    },
    {
      name: 'Western Australia',
      abbreviation: 'WA',
    },
    {
      name: 'Northern Territory',
      abbreviation: 'NT',
    },
    {
      name: 'Australian Capital Territory',
      abbreviation: 'ACT',
    },
  ],
};

export default function OrderForm45Content({
  formik,
  getGeopoint,
  zip,
  country,
  address,
  unit,
  state,
  city,
  bedrooms,
  parking,
}) {
  const [predictions, setPredictions] = useState([]);

  const onCountryChange = (e) => {
    formik.setFieldValue(state, states[e.target.value][0].name);
    formik.handleChange(e);
  };

  const getAutoComplete = async (searchStr, country, zipcode) => {
    const params = {
      input: searchStr,
      language: 'en',
      types: 'address',
      key: apiKey,
      components: `country:${country.toLowerCase()}`,
    };

    const geopoint = await getGeopoint(country, zipcode);
    if (geopoint) {
      params.location = geopoint;
      params.radius = '100';
    }

    try {
      const res = await axios.get(autoCompleteURL, { params });
      const { status, predictions } = res.data;
      if (status !== 'OK') {
        console.error(status);
        return [];
      } else return predictions.map((pred) => pred.description);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const onAddressChange = async (e) => {
    formik.handleChange(e);

    const newPredicitons = await getAutoComplete(
      formik.values[address],
      formik.values[country].slice(0, 2),
      formik.values[zip]
    );
    setPredictions(newPredicitons);
  };

  const parseAdr = (source) => {
    let city, state;
    const completionArr = source.split(', ');
    if (completionArr.length < 4) {
      const cityStateArr = completionArr[1].split(' ');
      state = cityStateArr.pop();
      city = cityStateArr.join(' ');
    } else {
      state = completionArr[2];
      city = completionArr[1];
    }

    return [completionArr[0], city, state];
  };

  const onAdrSelectHandler = (value) => {
    const [newAddress, newCity, newState] = parseAdr(value);
    const newVal = { ...formik.values };
    newVal[address] = newAddress;
    newVal[city] = newCity;
    newVal[state] = states[formik.values[country]].find(
      (item) => item.abbreviation === newState
    ).name;
    formik.setValues(newVal);
  };

  return (
    <div className='order-form-4-5-wrapper'>
      <div className='row1'>
        <LabelAutocomplete
          name={address}
          label='Address*'
          value={formik.values[address]}
          onSelect={onAdrSelectHandler}
          onChange={onAddressChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched[address] && formik.errors[address]
              ? formik.errors[address]
              : null
          }
          items={predictions}
        />
        <LabelInput label='Unit' name={unit} {...formik.getFieldProps(unit)} />
      </div>
      <div className='row2'>
        <LabelInput
          name={city}
          label='City*'
          {...formik.getFieldProps(city)}
          error={
            formik.touched[city] && formik.errors[city]
              ? formik.errors[city]
              : null
          }
        />
        <LabelSelect
          label='State*'
          name={state}
          {...formik.getFieldProps(state)}
        >
          {states[formik.values[country]].map((state, index) => (
            <option key={index} value={state.name}>
              {state.name}
            </option>
          ))}
        </LabelSelect>
      </div>
      <div className='row3'>
        <LabelInput
          label='Zip Code*'
          name={zip}
          {...formik.getFieldProps(zip)}
          error={
            formik.touched[zip] && formik.errors[zip]
              ? formik.errors[zip]
              : null
          }
        />
        <LabelSelect
          name={country}
          label='Country*'
          onChange={onCountryChange}
          value={formik.values[country]}
        >
          <option value='US'>USA</option>
          <option value='AU'>AUS</option>
        </LabelSelect>
      </div>
      <div className='row4'>
        <LabelInput
          name={bedrooms}
          label='Bedrooms*'
          {...formik.getFieldProps(bedrooms)}
          error={
            formik.touched[bedrooms] && formik.errors[bedrooms]
              ? formik.errors[bedrooms]
              : null
          }
        />
        <LabelSelect
          label='Mover Parking*'
          name={parking}
          {...formik.getFieldProps(parking)}
        >
          <option value='Street'>Street</option>
          <option value='Designated'>Designated</option>
          <option value='Driveway'>Driveway</option>
          <option value='Other'>Other</option>
        </LabelSelect>
      </div>
    </div>
  );
}
