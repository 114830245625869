import { useState, useEffect } from 'react';
import PageTemplate from './PageTemplate';
import ProgressBar from './ProgressBar';
import OrderForm0 from './OrderForm0';
import OrderForm1 from './OrderForm1';
import OrderForm2 from './OrderForm2';
import OrderForm3 from './OrderForm3';
import OrderForm4 from './OrderForm4';
import OrderForm5 from './OrderForm5';
import OrderForm7 from './OrderForm7';
import Review from './Review';
import Pending from './Pending';
import Info from './Info';
import './OrderVisual.scss';

export default function OrderVisual({
  formik,
  save,
  getGeopoint,
  awaitFlag,
  dataLoaded,
  uploadMediaFiles,
  orderId,
  state,
  submitOrder,
  filesUrls,
  videosUrls,
}) {
  const [step, setStep] = useState(0);
  const [noTransition, setNoTransition] = useState(false);

  useEffect(() => {
    const {
      zipFrom,
      zipTo,
      firstName,
      lastName,
      email,
      phone,
      storageDate,
      storageRequired,
      addressFrom,
      cityFrom,
      bedroomsFrom,
      addressTo,
      cityTo,
      bedroomsTo,
      videos,
    } = formik.values;

    const getCurStep = () => {
      let step = 0;

      if (zipFrom && zipTo) {
        step = 2;

        if (firstName && lastName && email && phone) {
          step = 3;

          if (!storageRequired || storageDate) {
            step = 4;

            if (addressFrom && cityFrom && bedroomsFrom) {
              step = 5;

              if (addressTo && cityTo && bedroomsTo) {
                step = 6;

                if (videos.length > 0) {
                  step = 7;

                  if (state === 'new') {
                    step = 8;
                  }
                }
              }
            }
          }
        }
      }

      return step;
    };

    if (dataLoaded) {
      setNoTransition(true);
      setTimeout(() => setNoTransition(false), 500);
      setStep(getCurStep());
    }
  }, [dataLoaded]);

  const nextStep = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setStep((curStep) => (curStep += 1));
  };

  const prevStep = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setStep((curStep) => (curStep -= 1));
  };

  const setStepWrapper = (step) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setNoTransition(true);
    setTimeout(() => setNoTransition(false), 500);
    setStep(step);
  };

  const formStatus = (formIndex) => {
    const noTransitionClass = noTransition ? 'no-transition ' : '';
    if (step > formIndex) return noTransitionClass + 'left';
    if (step < formIndex) return noTransitionClass + 'right';
    return 'active';
  };

  return (
    <PageTemplate>
      <div className='order-wrapper'>
        <ProgressBar step={step} total={6} />
        <form>
          <OrderForm0
            onNext={() => {
              save();
              nextStep();
            }}
            status={formStatus(0)}
            formik={formik}
            awaitFlag={awaitFlag}
          />
          <OrderForm1
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(1)}
            formik={formik}
          />
          <OrderForm2
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(2)}
            formik={formik}
          />
          <OrderForm3
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(3)}
            formik={formik}
          />
          <OrderForm4
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(4)}
            formik={formik}
            getGeopoint={getGeopoint}
          />
          <OrderForm5
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(5)}
            formik={formik}
            getGeopoint={getGeopoint}
          />
          <OrderForm7
            onNext={nextStep}
            onBack={prevStep}
            status={formStatus(6)}
            formik={formik}
            files={filesUrls.length > 0 ? filesUrls : videosUrls}
          />
          <Review
            status={formStatus(7)}
            onNext={async () => {
              if (filesUrls.length > 0) await uploadMediaFiles();
              try {
                await submitOrder();
                nextStep();
              } catch (error) {
                return error.message;
              }
            }}
            setStep={setStepWrapper}
            formik={formik}
            files={filesUrls.length > 0 ? filesUrls : videosUrls}
          />
          <Pending status={formStatus(8)} onNext={nextStep} orderId={orderId} />
          <Info
            status={formStatus(9)}
            onBack={prevStep}
            formik={formik}
            files={videosUrls}
          />
        </form>
      </div>
    </PageTemplate>
  );
}
