import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, errMsg } from '../utils/firebase';
import { Link } from 'react-router-dom';
import EmailPasswordPopup from './EmailPasswordPopup';
import './LoginEmail.scss';

export default function LoginEmail({
  show,
  onClose,
  onForgetPwdClick,
  setUserCred,
}) {
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const submitHandler = async ({ email, password }) => {
    const userCred = await signInWithEmailAndPassword(email, password);
    if (userCred) {
      setUserCred(userCred);
      onClose();
    }
  };

  return (
    <EmailPasswordPopup
      show={show}
      header='Sign in with email'
      btnText='Log in'
      onSubmit={submitHandler}
      onClose={onClose}
      error={errMsg(error)}
      spinner={loading}
      noName
    >
      <div className='link-wrapper'>
        <Link to='#' onClick={onForgetPwdClick}>
          Forgot password
        </Link>
      </div>
    </EmailPasswordPopup>
  );
}
