import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Details from './Details';

export default function Info({ status, onBack, formik, files }) {
  return (
    <OrderFormTemplate1Btn
      header='Order Details'
      btnText='BACK'
      onClick={onBack}
      status={status}
      btnBackground='white'
      btnColor='black'
      noMarginTop
    >
      <Details formik={formik} files={files} />
    </OrderFormTemplate1Btn>
  );
}
