import './Radio.scss';

export default function Radio({ value, label, name, checked, onChange }) {
  return (
    <div className='radio-label-wrapper'>
      <input
        type='radio'
        value={value}
        id={value}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
}
