import { useRef } from 'react';
import Button from './Button';
import OrderFormTemplate from './OrderFormTemplate';
import FilesPreview from './FilesPreview';
import './OrderForm7.scss';

const imagesExt =
  '.jpg, .jpeg, .jpe .jif, .jfif, .jfi, .png, .gif, .webp, .tiff, .tif, .psd, .raw, .arw, .cr2, .nrw, .k25, .bmp, .dib, .heif, .heic, .ind, .indd, .ind, .jp2, .j2k, .jpf, .jpx, .jpm, .mj2';

const videoExt =
  '.WEBM, .MPG, .MP2, .MPEG, .MPE, .MPV, .OGG, .MP4, .M4P, .M4V, .AVI, .WMV, .MOV, .QT, .AVCHD';

export default function OrderForm7({ onNext, onBack, status, formik, files }) {
  const fileInputRef = useRef(null);

  const uploadClickHandler = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const fileInputClickHandler = (event) => {
    formik.setValues({ ...formik.values, files: event.target.files });
  };

  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      files: true,
    });

    const errors = await formik.validateForm();
    if (!errors.files) onNext();
  };

  return (
    <OrderFormTemplate
      header='Upload relevant images and videos'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
    >
      <div className='order-form-7-wrapper'>
        <p className='go-to-mobile'>
          Please login from your mobile device
          <br />
          to upload a video
        </p>
        <label
          className={
            formik.touched.files && formik.errors.files ? 'error' : 'invisible'
          }
        >
          {formik.errors.files}
        </label>
        {files.length > 0 && <FilesPreview files={files} />}
        <input
          type='file'
          multiple
          accept={
            videoExt +
            (formik.values.moveType === 'Freight' ? ', ' + imagesExt : '')
          }
          ref={fileInputRef}
          onChange={fileInputClickHandler}
        />
        <Button onClick={uploadClickHandler}>
          <div className='button-content-wrapper'>
            <div className='icon' />
            <p>
              SELECT
              <br />
              FILES
            </p>
          </div>
        </Button>
      </div>
    </OrderFormTemplate>
  );
}
