import Spinner from './Spinner';
import Button from './Button';
import './OrderFormTemplate1Btn.scss';

export default function OrderFormTemplate1Btn({
  children,
  header,
  btnText,
  btnBackground,
  btnColor,
  btnNoshadow,
  btnType,
  onClick,
  status,
  small,
  underBtnEl,
  noMarginTop,
  onClose,
  spinner,
}) {
  return (
    <div
      className={
        'order-form-1-btn ' +
        (status ?? '') +
        (noMarginTop ? ' no-margin-top' : '')
      }
    >
      <div className='inner-wrapper'>
        <Spinner show={spinner} />
        {onClose && (
          <div className='close-button-wrapper'>
            <Button
              small
              onClick={onClose}
              noshadow
              background='white'
              className='close-button'
            >
              <div className='close-icon' />
            </Button>
          </div>
        )}
        <h5 className={onClose ? 'h-padding' : ''}>{header}</h5>
        <div className='children-wrapper'>{children}</div>
        <Button
          small={small}
          onClick={onClick}
          type={btnType}
          background={btnBackground}
          color={btnColor}
          noshadow={btnNoshadow}
        >
          {btnText}
        </Button>
        {underBtnEl && <div className='under-btn-el-wrapper'>{underBtnEl}</div>}
      </div>
    </div>
  );
}
