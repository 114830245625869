import './FilesPreview.scss';

export default function FilesPreview({ files }) {
  return (
    <div className='preview-wrapper'>
      {files &&
        files.map((file, index) => {
          if (file.type === 'image')
            return (
              <div
                key={index}
                className='preview'
                style={{ backgroundImage: `url(${file.url})` }}
              />
            );
          if (file.type === 'video')
            return <video src={file.url} key={index} />;
          return <></>;
        })}
    </div>
  );
}
