import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { auth, functions, db, storage } from '../utils/firebase';
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuthState } from 'react-firebase-hooks/auth';
import OrderVisual from '../components/OrderVisual';
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { addDays } from '../utils/miscellaneous';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

const initialValues = {
  moveType: 'Household',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  pickUpDate: addDays(new Date(), 7),
  pickUpTime: '8AM - 9AM',
  storageRequired: false,
  storageDate: null,
  selfPack: false,
  zipFrom: '',
  countryFrom: 'US',
  addressFrom: '',
  unitFrom: '',
  cityFrom: '',
  stateFrom: 'Alabama',
  bedroomsFrom: '',
  parkingFrom: 'Street',
  zipTo: '',
  countryTo: 'AU',
  addressTo: '',
  unitTo: '',
  cityTo: '',
  stateTo: 'New South Wales',
  bedroomsTo: '',
  parkingTo: 'Street',
  files: null,
  videos: [],
};

const minZip = (country) => {
  switch (country) {
    case 'AU':
      return 1000;
    case 'US':
      return 10000;
    default:
      return 0;
  }
};

const maxZip = (country) => {
  switch (country) {
    case 'AU':
      return 9999;
    case 'US':
      return 99999;
    default:
      return 0;
  }
};

const geopoints = {};

const geopointKey = (country, zipcode) => `${country}-${zipcode}`;

export default function Order() {
  const [countryFrom, setCountryFrom] = useState(initialValues.countryFrom);
  const [countryTo, setCountryTo] = useState(initialValues.countryTo);
  const [orderId, setOrderId] = useState(null);
  const [state, setState] = useState('init');
  const [awaitFlag, setAwaitFlag] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filesUrls, setFilesUrls] = useState([]);
  const [videosUrls, setVideosUrls] = useState([]);
  const [user] = useAuthState(auth);
  const userGetGeopoint = httpsCallable(functions, 'user-getGeopointV2');
  const orderSave = httpsCallable(functions, 'order-save');
  const orderCreate = httpsCallable(functions, 'order-create');
  const orderSubmitRequest = httpsCallable(functions, 'order-submitRequest');

  const getGeopoint = async (country, zipcode) => {
    const key = geopointKey(country, zipcode);

    if (!geopoints[key]) {
      const geopoint = await userGetGeopoint({ country, zipcode });
      if (geopoint) {
        geopoints[key] = geopoint;
      }
    }

    return geopoints[key];
  };

  const latLngGeo = (geopoint) => {
    if (geopoint?.data) {
      const coords = geopoint.data.geopoint.split(',');
      const lat = +coords[0];
      const lng = +coords[1];
      return { lat, lng };
    }

    return undefined;
  };

  const validationSchema = new Yup.ObjectSchema({
    zipFrom: Yup.number()
      .typeError('Not a number')
      .min(minZip(countryFrom), 'too short')
      .max(maxZip(countryFrom), 'too long')
      .required('Required'),
    zipTo: Yup.number()
      .typeError('Not a number')
      .min(minZip(countryTo), 'too short')
      .max(maxZip(countryTo), 'too long')
      .required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Invalid phone')
      .required('Required'),
    pickUpDate: Yup.date(),
    storageRequired: Yup.boolean(),
    storageDate: Yup.date().nonNullable('Required').required('Required'),
    addressFrom: Yup.string().required('Required'),
    cityFrom: Yup.string().required('Required'),
    bedroomsFrom: Yup.number().typeError('Not a number').required('Required'),
    addressTo: Yup.string().required('Required'),
    cityTo: Yup.string().required('Required'),
    bedroomsTo: Yup.number().typeError('Not a number').required('Required'),
    files:
      videosUrls.length > 0
        ? undefined
        : Yup.mixed().nonNullable('Please choose at least one media file'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (formik.values.files)
      setFilesUrls(
        Array.from(formik.values.files).map((file) => ({
          type: file.type.split('/')[0],
          url: URL.createObjectURL(file),
        }))
      );

    return () => {
      if (filesUrls)
        filesUrls.forEach((fileUrl) => URL.revokeObjectURL(fileUrl.url));
    };
  }, [formik.values.files]);

  useEffect(() => {
    const getFilesUrls = async () => {
      const getUrlPromises = [];
      const getMetaPromises = [];

      formik.values.videos.forEach((video) => {
        const fileRef = ref(storage, `videos/${orderId}/${video}`);
        getUrlPromises.push(getDownloadURL(fileRef));
        getMetaPromises.push(getMetadata(fileRef));
      });

      const res = await Promise.all([
        Promise.all(getUrlPromises),
        Promise.all(getMetaPromises),
      ]);

      setVideosUrls(
        res[0].map((url, i) => ({
          url,
          type: res[1][i].contentType.split('/')[0],
        }))
      );
    };

    getFilesUrls();
  }, [formik.values.videos]);

  useEffect(() => {
    const load = async () => {
      const { uid } = user;
      const docsData = [];

      //Fetching current docs for user to docsData
      const quotes = collection(db, 'quotes');
      const queryQuote = query(
        quotes,
        where('userID', '==', uid),
        where('state', 'in', ['init', 'new'])
      );
      const docs = await getDocs(queryQuote);
      docs.forEach((doc) => docsData.push({ ...doc.data(), id: doc.id }));

      if (docsData.length === 0) return;

      const timestampToDate = (timestamp) => {
        return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
      };

      setOrderId(docsData[0].id);
      setState(docsData[0].state);
      formik.setValues({
        ...formik.values,
        moveType: docsData[0].moveType,
        firstName: docsData[0].contact.name.split(' ')[0],
        lastName: docsData[0].contact.name.split(' ').slice(1).join(' '),
        email: docsData[0].contact.email,
        phone: docsData[0].contact.phone,
        pickUpDate: timestampToDate(docsData[0].details.moveDate),
        pickUpTime: docsData[0].details.moveWindow,
        storageRequired: docsData[0].details.storageRequired,
        storageDate: docsData[0].details.moveInDate
          ? timestampToDate(docsData[0].details.moveInDate)
          : null,
        selfPack: docsData[0].details.noPacking,
        zipFrom: docsData[0].pickup.zip,
        countryFrom: docsData[0].pickup.country,
        addressFrom: docsData[0].pickup.address,
        // unitFrom: '',
        cityFrom: docsData[0].pickup.city,
        stateFrom: docsData[0].pickup.state,
        bedroomsFrom: docsData[0].pickup.bedroomCount,
        parkingFrom: docsData[0].pickup.parking,
        zipTo: docsData[0].destination.zip,
        countryTo: docsData[0].destination.country,
        addressTo: docsData[0].destination.address,
        // unitTo: '',
        cityTo: docsData[0].destination.city,
        stateTo: docsData[0].destination.state,
        bedroomsTo: docsData[0].destination.bedroomCount,
        parkingTo: docsData[0].destination.parking,
        videos: docsData[0].videos ?? [],
      });
    };

    const loadWrapper = async () => {
      setAwaitFlag(true);
      await load();
      setAwaitFlag(false);
      setDataLoaded(true);
    };

    if (user) loadWrapper();
  }, [user]);

  const submitOrder = async () => {
    await orderSubmitRequest({ orderID: orderId });
  };

  const uploadMediaFiles = async () => {
    const uploadRes = await Promise.allSettled(
      Array.from(formik.values.files).map((file) => {
        const fileExt = file.name.split('.').pop();
        const fileName = uuidv4() + '.' + fileExt;
        const storageRef = ref(storage, `videos/${orderId}/${fileName}`);
        return uploadBytes(storageRef, file);
      })
    );

    const videos = uploadRes
      .filter((item) => item.status === 'fulfilled')
      .map((item) => item.value.metadata.name);

    formik.setFieldValue('videos', videos);

    orderSave({ orderID: orderId, videos });
  };

  const save = async () => {
    let localOrderId = null;

    if (!orderId) {
      const res = await orderCreate();
      localOrderId = res.data.orderID;
      setOrderId(localOrderId);
    }

    const {
      moveType,
      email,
      firstName,
      lastName,
      phone,
      pickUpDate,
      pickUpTime,
      selfPack,
      storageRequired,
      storageDate,
      addressFrom,
      bedroomsFrom,
      cityFrom,
      parkingFrom,
      stateFrom,
      zipFrom,
      addressTo,
      bedroomsTo,
      cityTo,
      zipTo,
      parkingTo,
      stateTo,
    } = formik.values;

    orderSave({
      orderID: orderId ?? localOrderId,
      moveType,
      contact: {
        email,
        name: firstName + ' ' + lastName,
        phone,
      },
      details: {
        moveDate: new Date(pickUpDate.setHours(12, 0, 0)),
        moveWindow: pickUpTime,
        noPacking: selfPack,
        storageRequired: storageRequired,
        moveInDate: storageDate
          ? new Date(storageDate.setHours(12, 0, 0))
          : undefined,
      },
      destination: {
        address: addressTo,
        bedroomCount: bedroomsTo,
        city: cityTo,
        // geopoint: latLngGeo(await getGeopoint(countryTo, zipTo)),
        parking: parkingTo,
        state: stateTo,
        country: countryTo,
        zip: zipTo,
      },
      pickup: {
        address: addressFrom,
        bedroomCount: bedroomsFrom,
        city: cityFrom,
        // geopoint: latLngGeo(await getGeopoint(countryFrom, zipFrom)),
        parking: parkingFrom,
        state: stateFrom,
        country: countryFrom,
        zip: zipFrom,
      },
    });
  };

  useEffect(() => {
    formik.validateField('zipFrom');
  }, [countryFrom]);

  useEffect(() => {
    formik.validateField('zipTo');
  }, [countryTo]);

  useEffect(() => {
    setCountryFrom(formik.values.countryFrom);
  }, [formik.values.countryFrom]);

  useEffect(() => {
    setCountryTo(formik.values.countryTo);
  }, [formik.values.countryTo]);

  return (
    <OrderVisual
      formik={formik}
      save={save}
      getGeopoint={getGeopoint}
      awaitFlag={awaitFlag}
      dataLoaded={dataLoaded}
      uploadMediaFiles={uploadMediaFiles}
      orderId={orderId}
      state={state}
      submitOrder={submitOrder}
      filesUrls={filesUrls}
      videosUrls={videosUrls}
    />
  );
}
