import { Link } from 'react-router-dom';
import './SignUpLogin.scss';

export default function SignUpLogin({ onClickSignUp, onClickLogin }) {
  return (
    <div className='signup-login'>
      <div className='text-wrapper'>
        <Link to='#' onClick={onClickSignUp}>
          Sign up
        </Link>
        <p>or</p>
        <Link to='#' onClick={onClickLogin}>
          Login
        </Link>
      </div>
    </div>
  );
}
