import './CheckBox.scss';

export default function CheckBox({ label, name, checked, value, onChange }) {
  return (
    <div className='checkbox'>
      <input
        type='checkbox'
        id={name}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}
