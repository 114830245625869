import ModalPopup from './ModalPopup';

export default function SuccessResetPwd({ show, onClose }) {
  return (
    <ModalPopup
      show={show}
      header='Success!'
      btnText='OK'
      onClick={onClose}
      onClose={onClose}
    >
      <p>
        A password reset link was sent
        <br />
        to the e-mail. Please check.
      </p>
    </ModalPopup>
  );
}
