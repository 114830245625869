import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, errMsg } from '../utils/firebase';
import EmailPasswordPopup from './EmailPasswordPopup';

export default function SignUpEmail({
  show,
  onClose,
  setUserCred,
  setUserName,
}) {
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  const submitHandler = async ({ email, password, name }) => {
    const userCred = await createUserWithEmailAndPassword(email, password);
    if (userCred) {
      setUserName(name);
      setUserCred(userCred);
      onClose();
    }
  };

  return (
    <EmailPasswordPopup
      show={show}
      header='Sign up with email'
      btnText='Sign up'
      onSubmit={submitHandler}
      onClose={onClose}
      error={errMsg(error)}
      spinner={loading}
    />
  );
}
