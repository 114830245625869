import Container from './Container';
import Button from './Button';
import './Navigation.scss';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';
import { useSignOut, useAuthState } from 'react-firebase-hooks/auth';

export default function Navigation() {
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  return (
    <div className='navigation'>
      <Container>
        <div className='nav-wrapper'>
          <Link to='#'>
            <div className='logo' />
          </Link>
          <div className='user-block'>
            <div className='username-wrapper'>
              <div className='user-icon' />
              <p>{user?.displayName ? user?.displayName : user?.email}</p>
            </div>
            <Button
              background='white'
              color='black'
              onClick={() => signOut()}
              small
            >
              LOG OUT
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
