import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import './ModalPopup.scss';

export default function ModalPopup({
  children,
  show,
  header,
  btnText,
  onClick,
  onClose,
}) {
  return (
    <div className={'modal-popup' + (show ? ' show' : '')}>
      <OrderFormTemplate1Btn
        header={header}
        btnText={btnText}
        onClick={onClick}
        noMarginTop
        small
        onClose={onClose}
      >
        {children}
      </OrderFormTemplate1Btn>
    </div>
  );
}
