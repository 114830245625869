import pencilIcon from '../assets/images/pencil.png';
import IconButton from './IconButton';
import './DetailsBlock.scss';

export default function DetailsBlock({ children, header, edit, onClick }) {
  return (
    <div className='details-block'>
      <div className='header-wrapper'>
        <h6>{header}</h6>
        <div className={'icon-button-wrapper' + (edit ? ' edit' : '')}>
          <IconButton icon={pencilIcon} small noshadow onClick={onClick} />
        </div>
      </div>
      <div className='info-wrapper'>{children}</div>
    </div>
  );
}
