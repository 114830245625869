import './LabelSelect.scss';

export default function LabelSelect({
  children,
  label,
  value,
  onChange,
  name,
}) {
  return (
    <div className='label-select'>
      <label>{label}</label>
      <select value={value} onChange={onChange} name={name}>
        {children}
      </select>
    </div>
  );
}
