import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const devConfig = {
  apiKey: 'AIzaSyAx-i3luVOn2d4iIFc1R9hCvC6XVWIaP08',
  authDomain: 'muva-backend-dev.firebaseapp.com',
  databaseURL: 'https://muva-backend-dev.firebaseio.com',
  projectId: 'muva-backend-dev',
  storageBucket: 'muva-backend-dev.appspot.com',
  messagingSenderId: '535024031644',
  appId: '1:535024031644:web:1d193f70f13afc1e9eda59',
  measurementId: 'G-TPER9MT4R8',
};

const firebaseConfig = {
  apiKey: 'AIzaSyAqLMsS5QKyaavYDRjqnHwbOWde4M8yZyo',
  authDomain: 'muva-backend.firebaseapp.com',
  databaseURL: 'https://muva-backend.firebaseio.com',
  projectId: 'muva-backend',
  storageBucket: 'muva-backend.appspot.com',
  messagingSenderId: '349722643142',
  appId: '1:349722643142:web:8ea00877c238394c5ba5c7',
};

const app = initializeApp(devConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const errMsg = (error) => {
  let msg = '';

  if (error) {
    switch (error?.code) {
      case 'auth/email-already-in-use':
        msg = 'Email already in use';
        break;
      case 'auth/invalid-email':
        msg = 'Invalid email';
        break;
      case 'auth/weak-password':
        msg = 'Password too weak';
        break;
      case 'auth/user-not-found':
        msg = 'User not found';
        break;
      case 'auth/wrong-password':
        msg = 'Invalid user or password';
        break;
      case 'auth/user-disabled':
        msg = 'User disabled';
        break;
      default:
        msg = 'Error';
        break;
    }
  }

  return msg;
};
