import './IconButton.scss';

export default function IconButton({
  text,
  color = 'black',
  background = 'white',
  icon,
  small,
  onClick,
  noshadow,
}) {
  return (
    <button
      className='icon-button'
      style={{
        color: color,
        backgroundColor: background,
        padding: small ? '8px 16px' : undefined,
        boxShadow: noshadow ? 'none' : undefined,
      }}
      onClick={onClick}
      type='button'
    >
      <div className='icon-text-wrapper'>
        <div className='icon' style={{ backgroundImage: `url(${icon})` }} />
        {text && (
          <div className='text-wrapper'>
            <p>{text}</p>
          </div>
        )}
      </div>
    </button>
  );
}
