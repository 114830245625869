import './LabelInput.scss';

export default function LabelInput({
  label,
  type = 'text',
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  autoFocus,
}) {
  return (
    <div className='label-input'>
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <input
        ref={(el) => {
          if (autoFocus && el) el.focus();
        }}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
}
