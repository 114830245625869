export default function Button({
  children,
  background,
  color,
  noshadow,
  small,
  onClick,
  type = 'button',
}) {
  return (
    <button
      className='button'
      style={{
        backgroundColor: background ?? 'var(--primary-color)',
        color: color ?? 'white',
        boxShadow: noshadow ? 'none' : undefined,
        padding: small ? '8px 16px' : undefined,
        borderRadius: small ? '4px' : undefined,
      }}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}
