import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import './Pending.scss';

export default function Pending({ status, onNext, orderId }) {
  return (
    <OrderFormTemplate1Btn
      header='Your Quote Status'
      btnText='SEE DETAILS'
      onClick={onNext}
      status={status}
      btnBackground='white'
      btnColor='var(--primary-color)'
      small
    >
      <div className='pending-wrapper'>
        <h3>Pending</h3>
        <label>{`Tracking ID: ${orderId}`}</label>
      </div>
    </OrderFormTemplate1Btn>
  );
}
