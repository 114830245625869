import EmailPasswordPopup from './EmailPasswordPopup';
import { auth, errMsg } from '../utils/firebase';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

export default function ForgetPwd({ show, onClose, onSubmit }) {
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  const submitHandler = async ({ email }) => {
    const success = await sendPasswordResetEmail(email);
    if (success) onSubmit();
  };

  return (
    <EmailPasswordPopup
      show={show}
      header='Reset password'
      btnText='Reset'
      onSubmit={submitHandler}
      onClose={onClose}
      error={errMsg(error)}
      spinner={sending}
      noName
      noPassword
    />
  );
}
