import { useState } from 'react';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Details from './Details';
import { Link } from 'react-router-dom';
import TermsConditions from '../assets/docs/terms-of-service.pdf';
import './Review.scss';

export default function Review({ status, onNext, setStep, formik, files }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitErr, setSubmitErr] = useState(undefined);

  const handleOnClick = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    setSubmitErr(await onNext());
    setShowSpinner(false);
  };

  const warning = (
    <div className='warning-wrapper'>
      <p>By clicking Submit you&nbsp;agree&nbsp;to&nbsp;our</p>
      <Link to={TermsConditions} target='_blank'>
        Terms & Conditions
      </Link>
    </div>
  );

  return (
    <OrderFormTemplate1Btn
      header='Review Details'
      btnText='SUBMIT'
      onClick={handleOnClick}
      btnType='submit'
      status={status}
      underBtnEl={warning}
      noMarginTop
      spinner={showSpinner}
    >
      <Details
        edit
        setStep={(step) => {
          setSubmitErr(undefined);
          setStep(step);
        }}
        formik={formik}
        files={files}
        error={submitErr}
      />
    </OrderFormTemplate1Btn>
  );
}
