import Button from './Button';
import './OrderFormTemplate.scss';

export default function OrderFormTemplate({
  header,
  onNext,
  onBack,
  status,
  children,
}) {
  return (
    <div className={'order-form-template ' + status}>
      <div className='inner-wrapper'>
        <div className='header-wrapper'>
          <h5>{header}</h5>
        </div>
        <div className='form-wrapper'>
          <form>{children}</form>
          <div className='buttons-wrapper'>
            <Button
              background='white'
              color='black'
              onClick={onBack}
              type='button'
            >
              BACK
            </Button>
            <Button onClick={onNext} type='button'>
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
