import DetailsBlock from './DetailsBlock';
import LabelText from './LabelText';
import moment from 'moment';
import './Details.scss';
import FilesPreview from './FilesPreview';

export default function Details({ setStep, edit, formik, files, error }) {
  const {
    firstName,
    lastName,
    email,
    phone,
    zipFrom,
    countryFrom,
    addressFrom,
    unitFrom,
    cityFrom,
    stateFrom,
    bedroomsFrom,
    parkingFrom,
    addressTo,
    unitTo,
    cityTo,
    stateTo,
    zipTo,
    countryTo,
    bedroomsTo,
    parkingTo,
    pickUpDate,
    pickUpTime,
    storageRequired,
    storageDate,
    selfPack,
  } = formik.values;

  return (
    <div className='details'>
      <DetailsBlock
        header='Contact Information'
        edit={edit}
        onClick={() => setStep(2)}
      >
        <LabelText label='Full Name' text={`${firstName} ${lastName}`} />
        <LabelText label='Email Address' text={email} />
        <LabelText label='Phone Number' text={phone} />
      </DetailsBlock>
      <DetailsBlock header='Pickup' edit={edit} onClick={() => setStep(4)}>
        <LabelText
          label='Address'
          text={`${addressFrom} ${unitFrom} ${cityFrom}, ${stateFrom} ${zipFrom}, ${countryFrom}`}
        />
        <LabelText label='Bedrooms' text={bedroomsFrom} />
        <LabelText label='Parking' text={parkingFrom} />
      </DetailsBlock>
      <DetailsBlock header='Dropoff' edit={edit} onClick={() => setStep(5)}>
        <LabelText
          label='Address'
          text={`${addressTo} ${unitTo} ${cityTo}, ${stateTo} ${zipTo}, ${countryTo}`}
        />
        <LabelText label='Bedrooms' text={bedroomsTo} />
        <LabelText label='Parking' text={parkingTo} />
      </DetailsBlock>
      <DetailsBlock
        header='Move Details'
        edit={edit}
        onClick={() => setStep(3)}
      >
        <LabelText
          label='Prefered Move Date'
          text={moment(pickUpDate).format('MMM D, YYYY')}
        />
        <LabelText label='Prefered Move Window' text={pickUpTime} />
        <LabelText
          label='Storage'
          text={
            storageRequired
              ? moment(storageDate).format('MMM D, YYYY')
              : 'Not required'
          }
        />
        <LabelText
          label='Options'
          text={selfPack ? 'Self packing' : 'No options'}
        />
      </DetailsBlock>
      <DetailsBlock header='Video' edit={edit} onClick={() => setStep(6)}>
        <FilesPreview files={files} />
      </DetailsBlock>
      <label className={'error-label' + (error ? ' show' : '')}>
        {error ?? 'placeholder'}
      </label>
    </div>
  );
}
