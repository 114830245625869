import OrderFormTemplate from './OrderFormTemplate';
import Radio from './Radio';
import './OrderForm1.scss';

export default function OrderForm1({ onNext, onBack, status, formik }) {
  return (
    <OrderFormTemplate
      header='What is your move type?'
      status={status}
      onNext={onNext}
      onBack={onBack}
    >
      <div className='radio-wrapper'>
        <Radio
          value='Household'
          label='Household'
          name='moveType'
          checked={formik.values.moveType === 'Household'}
          onChange={formik.handleChange}
        />
        <Radio
          value='Freight'
          label='Freight'
          name='moveType'
          checked={formik.values.moveType === 'Freight'}
          onChange={formik.handleChange}
        />
      </div>
    </OrderFormTemplate>
  );
}
