import './LabelTime.scss';

export default function LabelTime({ label, value, onChange, name }) {
  return (
    <div className='label-time'>
      <label>{label}</label>
      <select value={value} onChange={onChange} name={name}>
        <option value='8AM - 9AM'>8AM - 9AM</option>
        <option value='9AM - 10AM'>9AM - 10AM</option>
        <option value='10AM - 11AM'>10AM - 11AM</option>
        <option value='11AM - 12PM'>11AM - 12PM</option>
        <option value='12PM - 1PM'>12PM - 1PM</option>
        <option value='1PM - 2PM'>1PM - 2PM</option>
        <option value='2PM - 3PM'>2PM - 3PM</option>
        <option value='3PM - 4PM'>3PM - 4PM</option>
        <option value='4PM - 5PM'>4PM - 5PM</option>
        <option value='5PM - 6PM'>5PM - 6PM</option>
        <option value='6PM - 7PM'>6PM - 7PM</option>
        <option value='7PM - 8PM'>7PM - 8PM</option>
        <option value='8PM - 9PM'>8PM - 9PM</option>
      </select>
    </div>
  );
}
