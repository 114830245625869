import './IntroText.scss';

export default function IntroText() {
  return (
    <div className='intro-text'>
      <h3>Welcome to MUVA!</h3>
      <p>Welcome to those shipping goods from USA to Australia.</p>
      <p>
        Thanks to our partnership with the Ship Smart network, this site can
        provide the most efficient and the most time and cost-effective solution
        for shipment of both household and commercial goods from anywhere in the
        United States to anywhere in Australia.
      </p>
      <p>
        Each household and commercial move is almost always different. The
        network of moving and shipping companies, coupled with MUVA technology
        to quickly distribute the necessary information, brings forth the best
        solution for professional prompt delivery.
      </p>
      <h4>How it works is simple:</h4>
      <p>
        Begin by answering questions about your shipment. As you answer the
        questions the MUVA system will take you to the next screen. You will be
        prompted to provide either a video or pictures of the items depending on
        if the requirement is a household move, single item shipment or freight.
      </p>
      <p>
        Regardless of the shipment, the entire process takes only a few minutes
        to get the most accurate quote for your need.
      </p>
      <p>
        There is no cost for the quote and you get to request, review and ask
        questions at the time that’s most convenient for you.
      </p>
      <p>
        Everything about your move is tracked by MUVA technology. Payments are
        all easily processed over both the site and our app, all using 3rd party
        provides such that we never see your payment details.
      </p>
    </div>
  );
}
