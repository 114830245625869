import OrderFormTemplate from './OrderFormTemplate';
import OrderForm45Content from './OrderForm45Content';

export default function OrderForm5({
  onNext,
  onBack,
  status,
  formik,
  getGeopoint,
}) {
  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      zipTo: true,
      countryTo: true,
      addressTo: true,
      unitTo: true,
      cityTo: true,
      stateTo: true,
      bedroomsTo: true,
      parkingTo: true,
    });

    const errors = await formik.validateForm();
    if (
      !errors.zipTo &&
      !errors.countryTo &&
      !errors.addressTo &&
      !errors.unitTo &&
      !errors.cityTo &&
      !errors.stateTo &&
      !errors.bedroomsTo &&
      !errors.parkingTo
    )
      onNext();
  };

  return (
    <OrderFormTemplate
      header='Destination Address'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
    >
      <OrderForm45Content
        formik={formik}
        getGeopoint={getGeopoint}
        zip='zipTo'
        country='countryTo'
        address='addressTo'
        unit='unitTo'
        city='cityTo'
        state='stateTo'
        bedrooms='bedroomsTo'
        parking='parkingTo'
      />
    </OrderFormTemplate>
  );
}
