import './LabelText.scss';

export default function LabelText({ label, text }) {
  return (
    <div className='label-text'>
      <label>{label}</label>
      <p>{text}</p>
    </div>
  );
}
