import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModalPopup from './ModalPopup';
import LabelInput from './LabelInput';
import Spinner from './Spinner';
import './EmailPasswordPopup.scss';

export default function EmailPasswordPopup({
  children,
  show,
  header,
  btnText,
  onSubmit,
  onClose,
  error,
  noName,
  noPassword,
  spinner,
}) {
  const initialValues = {
    name: '',
    email: '',
    password: '',
  };

  const validationSchema = new Yup.ObjectSchema({
    name: noName ? Yup.string() : Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: noPassword ? Yup.string() : Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { email, password, name } = values;
      onSubmit({ email, password, name });
    },
  });

  return (
    <ModalPopup
      show={show}
      header={header}
      btnText={btnText}
      onClick={formik.submitForm}
      onClose={onClose}
    >
      <Spinner show={spinner} />
      <label className={'error-label' + (error ? ' show' : '')}>{error}</label>
      <form className='signup-email-form'>
        {!noName && (
          <LabelInput
            label='Name*'
            name='name'
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
        )}
        <LabelInput
          label='Email*'
          type='email'
          name='email'
          {...formik.getFieldProps('email')}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        {!noPassword && (
          <LabelInput
            label='Password*'
            type='password'
            name='password'
            {...formik.getFieldProps('password')}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        )}
      </form>
      {children}
    </ModalPopup>
  );
}
