import './LogoText.scss';

export default function LogoText() {
  return (
    <div className='logo-text'>
      <div className='logo' />
      <p className='text'>Moving Made Magic</p>
    </div>
  );
}
